<template>
  <b-card class="w-100 h-100">
    <h2 class="my-1">
      {{ title }}
    </h2>
    <ul>
      <li
        v-for=""
        :key="index"
        (item,
        index)
        in
        chartData"
        class="d-flex"
      >
        {{ item+1 }}.<span>{{ item.position }}</span>
        <span class="ml-1">{{ item.company_name }}</span>

      </li>
      <hr>
    </ul>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      title: 'Most Popular Jobs',
      chartData: {},

    }
  },
  created() {
    this.initComponent()
  },
  methods: {
    initComponent() {
      const reportId = this.$route.params.report_name.split('__')[1]

      this.isLoading = true
      this.$http
        .post('/api/event-reports/analytics', {
          form_data: {
            fetch_data: ['popular_kiosk_jobs'],
            report_id: reportId,
          },
        })
        .then(response => {
          const { reports } = response.data
          this.chartData = reports.popular_kiosk_jobs

          this.isLoading = false
        })
        .catch(error => {
          console.error(error)
          this.isLoading = false
        })
    },
  },
}
</script>

<style>

</style>
